<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
         <b-col
          cols="6"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.findCode')"
            />
          </div>
          <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="SearchData(searchQuery)"
              > Cari
          </b-button>
          </div>
        </b-col>

        <!-- Add -->
        <b-col
          cols="12"
          md="3"
          class="mb-1 d-flex align-items-center justify-content-end mb-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-transactions-cash-advance-request-add'}"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.cashadvance.actions.create') }}
          </b-button>
        </b-col>

      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-ignored-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      @row-clicked="rowClicked"
    >

      <template #table-busy>
        <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
          <div class="mb-1 text">
            <b-spinner class="align-middle"/>
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #cell(code)="data">
        <span class="font-weight-bolder text-primary d-block text-nowrap">
          {{ data.item.code }}
        </span>
      </template>

      <template #cell(employee)="data">
        <b-link
          :to="{ name: 'apps-master-data-contact-detail', params: { id: data.item.employee.id }}"
          class="font-weight-bold"
        >
          {{ data.item.employee.code }} - {{ data.item.employee.pic_name }}
        </b-link>
      </template>

      <template #cell(date)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.date }}
        </span>
      </template>

      <template #cell(purpose_date)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.purpose_date }}
        </span>
      </template>

      <template #cell(request_amount)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <template #cell(approved_amount)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="{ item }">
        <b-badge pill :variant="parseInt(item.approved_amount) > 0 ? 'light-success' : 'light-warning'">
          {{ parseInt(item.approved_amount) > 0 ? 'Approved' : 'Need Confirmation' }}
        </b-badge>
      </template>

      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

      <!-- Column: actions -->
      <!-- <template #cell(actions)="{item}">
        <div>
        <b-link
        @click="downloadPDF(item.id)"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
           <feather-icon
            icon="FileTextIcon"
            size="21"
          />
          </span>
        </b-link>
        </div>
      </template> -->

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mt-1 mb-0 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BButton,
  BBadge
} from 'bootstrap-vue'

// import useList from './useList'
import useListTablePage from '@/comp-functions/useListTablePage'
import {formatCurrency} from '@/utils/formatter'
// import useHttp from '@/comp-functions/useHttp'
import router from '@/router'
// import jsPDF from 'jspdf' 
// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'
// import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BButton,
    BBadge
  },
  setup () {
    const { $t }   = useLocalization()
    // const { $get } = useHttp()

    // Table Handlers
    const tableColumns = [
      { key: 'code', label: 'Code', sortable: true },
      { key: 'employee', label: 'Employee' },
      { key: 'date', label: 'Date' },
      { key: 'getOffice.brand', label: $t('globalSingular.branch') },
      { key: 'purpose_date', label: 'Purpose Date'},
      {
        key: 'request_amount',
        label: 'Request Amount',
        formatter: value => {
          return formatCurrency(value)
        }
      },
      {
        key: 'approved_amount',
        label: 'Approved Amount',
        formatter: value => {
          return parseInt(value) ? formatCurrency(value) : '-'
        }
      },
      { key: 'status', label: $t('globalSingular.status') },
      { key: 'audit', label: $t('globalSingular.audit') }
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'date',
      'purpose_date'
    ]

    const rowClicked = item => {
      router.push({
        name: 'apps-transactions-cash-advance-request-detail',
        params: { id: item.id }
      })
    }

    const SearchData = datasearch => {
        loadPage(`transaction/cash-advance/request?search=${datasearch.toUpperCase()}`)
    }

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      loadPage,
      changePerPage
    } = useListTablePage({ url: 'transaction/cash-advance/request?', urlcount: 'transaction/count-cash-advance/request' })


    return {
      rowClicked,
      tableColumns,
      formatCurrency,
      columnToBeFilter,
      changePerPage,
      SearchData,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
    }
  }
}
</script>

<style lang="scss">
#refListTable {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
</style>
